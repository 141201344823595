/*!
	* Tamara - Global Stylesheet (c) 2022 <bahaasamir.me>
*/
* {-webkit-font-smoothing:subpixel-antialiased; -webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale;font-kerning:auto;}.html{-webkit-text-size-adjust:none;-webkit-font-smoothing:subpixel-antialiased;-webkit-font-smoothing:antialiased}i{font-style:normal;}a,body,div,footer,h1,h2,h3,h4,h5,h6,header,html,img,li,nav,p,span,textarea,ul{margin:0;padding:0;border:0;outline:0;}h1,h2,h3,h4,h5,h6{font-weight:normal;line-height:1;}li,ul{list-style:none;}a{color:inherit;text-decoration:none;}a,img,picture,svg,video{display:block;}svg{max-width:100%;height:auto;}html{-ms-text-size-adjust:100%;-webkit-overflow-scrolling:touch;-webkit-tap-highlight-color:transparent;}*, :after, :before {-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
.flickity-enabled{position:relative}.flickity-enabled:focus{outline:0}.flickity-viewport{position:relative;height:100%;}.flickity-slider{position:absolute;width:100%;will-change: transform;}.flickity-enabled.is-draggable{-webkit-tap-highlight-color:transparent;tap-highlight-color:transparent;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.flickity-enabled.is-draggable .flickity-viewport{cursor: move;cursor: -webkit-grab;cursor: grab;} .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down{cursor: -webkit-grabbing;cursor: grabbing;}.flickity-prev-next-button{position:absolute;top:50%;width:44px;height:44px;border:none;border-radius:50%;background:#fff;background:hsla(0,0%,100%,.75);-webkit-transform:translateY(-50%);transform:translateY(-50%)}.flickity-prev-next-button:hover{background:#fff}.flickity-prev-next-button:focus{outline:0;box-shadow:0 0 0 5px #09f}.flickity-prev-next-button:active{opacity:.6}.flickity-prev-next-button.previous{left:10px}.flickity-prev-next-button.next{right:10px}.flickity-rtl .flickity-prev-next-button.previous{left:auto;right:10px}.flickity-rtl .flickity-prev-next-button.next{right:auto;left:10px}.flickity-prev-next-button:disabled{opacity:.3;}.flickity-prev-next-res_button svg{position:absolute;left:20%;top:20%;width:60%;height:60%}.flickity-prev-next-button .arrow{fill:#333}.flickity-page-dots{position:absolute;width:100%;bottom:-25px;padding:0;margin:0;list-style:none;text-align:center;line-height:1}.flickity-rtl .flickity-page-dots{direction:rtl}.flickity-page-dots .dot{display:inline-block;width:10px;height:10px;margin: 0 4px;background:#333;border-radius:50%;opacity:.25;}flickity-page-dots .dot.is-selected{opacity:1}
html.has-scroll-smooth{overflow:hidden}html.has-scroll-dragging{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.has-scroll-smooth body{overflow:hidden}.has-scroll-smooth [data-scroll-container]{min-height:calc(var(--vh, 1vh) * 100)}.c-scrollbar,.c-scrollbar2{height:100%;opacity:0;position:absolute;right:0;top:0;transform-origin:center right;transition:transform .3s,opacity .3s;width:11px}.c-scrollbar:hover,.c-scrollbar2:hover{transform:scaleX(1.45)}.c-scrollbar:hover,.c-scrollbar2:hover,.has-scroll-dragging .c-scrollbar,.has-scroll-scrolling .c-scrollbar{opacity:1}.c-scrollbar_thumb{background-color:#000;border-radius:10px;cursor:-webkit-grab;cursor:grab;margin:2px;opacity:.5;position:absolute;right:0;top:0;width:7px}.has-scroll-dragging .c-scrollbar_thumb{cursor:-webkit-grabbing;cursor:grabbing}.c-scrollbar2{z-index:3999}

/* Transitions */
body:not(.progress) .overlay_cover:before,
body:not(.progress) .overlay_cover:after {
	transition: all 1s cubic-bezier(0.22, 0.61, 0.36, 1);
	-webkit-transition: all 1s cubic-bezier(0.22, 0.61, 0.36, 1);
}

body:not(.progress).isMobile .featured_name p,
body:not(.progress).isMobile .featured_text p,
body:not(.progress).isDesktop .inline_button .button_content span:before,
body:not(.progress).isDesktop .inline_button .button_content p:before,
body:not(.progress).isDesktop .underline:before,
body:not(.progress).isDesktop .inline_button svg,
body:not(.progress).isDesktop:not(.isSafari) .store:not(.preload) article,
body:not(.progress).isDesktop:not(.isSafari) .store:not(.preload) article:before,
body:not(.progress) article .small_button,
body:not(.progress) .circle_button:before,
body:not(.progress) .circle_button svg,
body:not(.progress) .faq_head svg,
body:not(.progress) .bar_side,
body:not(.progress) .copy,
body:not(.progress) .close_side,
body:not(.progress) .mega_cats a:last-child svg,
body:not(.progress) .mega_cats a:after {
	transition: all 400ms cubic-bezier(0.22, 0.61, 0.36, 1);
	-webkit-transition: all 400ms cubic-bezier(0.22, 0.61, 0.36, 1);
}

body:not(.progress) .filter,
body:not(.progress) .mega_cats a,
body:not(.progress) header {
	transition: color 400ms cubic-bezier(0.22, 0.61, 0.36, 1);
	-webkit-transition: color 400ms cubic-bezier(0.22, 0.61, 0.36, 1);
}

body:not(.progress) .app_phone {
	transition: color 600ms linear;
	-webkit-transition: color 600ms linear;
}

body:not(.progress) ._temp,
body:not(.progress)#app {
	transition: background-color 600ms linear;
	-webkit-transition: background-color 600ms linear;
}

body:not(.progress) .button {
	transition: background-color 400ms cubic-bezier(0.22, 0.61, 0.36, 1);
	-webkit-transition: background-color 400ms cubic-bezier(0.22, 0.61, 0.36, 1);
}

*[data-scroll],
div[data-scroll-section],
._ele,
._eleY,
._eleX,
.SplitClass,
.deals_col,
.store,
.inline_button svg,
.rounded,
.rounded-all-less,
.featured_cover i,
.featured_store p,
.featured_details,
.circle_button svg,
.card_details,
.app_banner_wrap,
.app_phone_screen,
.as,
.close_side,
.deals_side,
.deal_cover i,
.mega_menu_wrap,
.map_set,
.minimal,
.burger_content .ft_col,
.burger_menu > i,
.burger_set i {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	will-change: transform;
}

._eleWrap,
._splitWords,
._splitLines,
._splitMask {
	visibility: hidden;
}

._hasInteraction {
	overflow: hidden;
}

._splitMask {
	position: relative;
}

._splitMask > div {
	overflow: hidden;
	top: 0.095em;
	padding: 0.095em;
}

._splitMask > div:not(first-child) {
	margin-top: -0.195em;
}

.underline {
	position: relative;
	display: inline-flex;
}

p .underline.purple {
	background: unset !important;
	color: #A68DBC;
}

/* Build */
body {
	background-color: #F2F2F2;
}

body#article {
	background: #fff;
}

body.progress {
	cursor: wait;
}

body,
input,
textarea {
	font-size: 0.833vw;
	line-height: 140%;
}

main {
	position: relative;
	overflow: hidden;
}

section, header, footer {
	margin: 2.34375vw;
}

section:not(:first-child),
.footer_container {
	margin-top: 0;
}

section {
	margin-bottom: 7.8125vw;
}

section.no-margin-top {
	margin-top: 0;
}

section.no-margin-bottom {
	margin-bottom: 0;
}

.flex {
	display: flex;
}

.flex-column {
	flex-direction: column;
}

.flex-wrap {
	flex-wrap: wrap;
}

.space-between {
	justify-content: space-between;
}

.v-center {
	align-items: center;
}

.x-center {
	justify-content: center;
}

.small_screen {
	display: none;
}


/* Typography  */
h1,h2,h3,h4,h5,h6 {
	padding-left: 0.095em;
	padding-right: 0.095em;
}
h1 {
	font-size: 6.77vw;
	line-height: 90%;
	padding-bottom: 0.1em;
}

h2 {
	font-size: 5.729vw;
	line-height: 95%;
	padding-bottom: 0.1em;
	margin-bottom: 0.3em;
}

h3 {
	font-size: 4.6875vw;
	line-height: 110%;
}

h4 {
	font-size: 3.125vw;
	line-height: 115%;
}

h5 {
	font-size: 2.0833vw;
	line-height: 115%;
}

h6 {
	font-size: 1.5625vw;
	line-height: 130%;
}

p,
ol {
	font-size: 1.1vw;
	line-height: 150%;
}

.label {
	font-size: 1.04166vw;
	line-height: 150%;
}

.micro {
	font-size: 0.572vw;
	line-height: 150%;
}

.uppercase {
	text-transform: uppercase;
}

.zero-font > span {
	font-size: 0;
	position: absolute;
}

/* Assets */
.spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 20px;
	height: 20px;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	z-index: 1999;
	pointer-events: none;
}

.spinner:before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border: 2px solid rgba(255, 255, 255, 0.3);
	border-bottom-color: #fff;
}

.spinner:not(.pause):before {
	-webkit-animation: rotateSpinner 300ms linear infinite;
	animation: rotateSpinner 300ms linear infinite;
}

@-webkit-keyframes rotateSpinner {
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes rotateSpinner {
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.full_bg {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.less_opacity:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 99;
	background: rgba(0,0,0,0.85);
}

.full_page {
	height: calc(var(--vh,1vh) * 100);
}

.cover {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

/* Header */
header {
	position: absolute;
	z-index: 1999;
	padding: 2.6041667vw 3.90625vw;
	left: 0;
	right: 0;
	top: 0;
	margin-bottom: 0 !important;
}

header.solid {
	background: #fff;
	position: relative;
}

header.light {
	color: #fff;
}

header.light .button_content {
	color: #000;
}

.header_sides {
	width: 100%;
	position: relative;
	z-index: 99;
}

.header_side:first-child {
	align-items: center;
}

.main_logo svg {
	width: 11.508125em;
	min-width: 114px;
}

.language svg {
	width:  3em;
	min-width: 40px;
}

nav > ul > li > a,
nav > ul > li > span {
	position: relative;
}

nav > ul > li,
nav > ul > li > a,
nav > ul > li > span {
	padding: 0 0.625em;
}

nav > ul > li > a:before,
nav > ul > li > span:before {
	content: '';
	position: absolute;
	width: 1.3125em;
	height: 3px;
	background: black;
	top: -4.3em;
	left: 50%;
	transform: translateX(-0.65625em);
	opacity: 0;
}

nav > ul > li > a.active:before,
nav > ul > li > span.active:before {
	opacity: 1;
}

nav > ul > li > a,
nav > ul > li > span {
	cursor: pointer;
}

nav > ul > li > a svg,
nav > ul > li > span svg {
	width: 0.5625em;
}

#home .breadcrumb_wrap {
	display: none;
}

body:not(#article) .back,
body:not(#casestudy) .cases,
body#article .breadcrumb,
body#casestudy .breadcrumb {
	display: none;
}

.breadcrumb_wrap {
	position: relative;
	top: 0.25em;
}

.breadcrumb svg {
	width: 0.5em;
	margin: 0 0.375em;
}

.breadcrumb a,
.breadcrumb span {
	padding: 0.1em;
	display: inline-block;
}

.breadcrumb span {
	color: rgba(0,0,0,0.6)
}

.light .breadcrumb span {
	color: rgba(255,255,255,0.6)
}

.back svg,
.cases svg {
	width: 0.75em;
}

.burger_set {
	display: none;
	position: relative;
	z-index: 299;
	cursor: pointer;
}

.burger_content {
	position: relative;
	z-index: 99;
}

.burger_set i {
	width: 20px;
	height: 3px;
	display: block;
	position: relative;
	background: currentColor;
	-webkit-transition: background-color 300ms linear;
	transition: background-color 300ms linear;
	margin: 3px 0;
}

.burger_set.colored i {
	background: #000;
}

.burger_set i:first-child {
	margin-top: 0;
}

.burger_menu {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 199;
	padding: 65px 10em;
	visibility: hidden;
}

.burger_menu > i {
	background: #fff;
	transform: scaleY(0);
	transform-origin: 0 0;
}

.burger_menu a {
	color: rgba(0,0,0,.7) !important;
}

.burger_content .ft_col {
	flex: 1;
}

/* Footer */
footer {
	margin-bottom: 0;
	margin-top: 0;
}
.footer_wrap {
	padding-bottom: 2.34375vw;
}

.footer_container {
	background: #000;
	color: #fff;
	position: relative;
	padding-top: 3.125em;
	padding-bottom: 3.125em;
	margin-bottom: 0;
}

.footer_set {
	max-width: 85em;
}

.ft_floating {
	position: absolute;
	top: 5.8em;
	z-index: 199;
	width: 2.78125em;
	height: 2.78125em;
	background: #252525;
	border-radius: 10px;
	cursor: pointer;
}

html[lang=ar] .ft_floating {
	top: 7.1em;
}

.ft_floating svg {
	width: 0.875em;
}

.ft_side .main_logo {
	margin-bottom: 1.25em;
}

.ft_side .micro,
.qr_text .micro {
	font-size: 1em;
}

.dropdown {
	position: relative;
}

.dropdown select {
	position: absolute;
	opacity: 0;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.isDesktop .dropdown select {
	pointer-events: none;
}

.dropdown_set {
	position: relative;
	width: 15em;
	cursor: pointer;
}

.dropdown_set img {
	width: 1.375em;
}

.dropdown_head {
	border-radius: 10px;
	background: #252525;
}

.dropdown_head svg {
	position: absolute;
	margin-top: 0.2em;
	width: 0.5625em;
}

.dropdown_options {
	background: #252525;
	position: absolute;
	left: 0;
	right: 0;
	-webkit-border-bottom-right-radius: 10px;
	-webkit-border-bottom-left-radius: 10px;
	-moz-border-radius-bottomright: 10px;
	-moz-border-radius-bottomleft: 10px;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	display: none;
}

.dropdown_head,
.dropdown_option {
	padding: 0.875em 1em;
}

.dropdown_option:hover {
	background: rgba(255,255,255,0.1);
}

.opened .dropdown_options {
	display: block;
}

.opened .dropdown_head {
	-webkit-border-bottom-right-radius: 0;
	-webkit-border-bottom-left-radius: 0;
	-moz-border-radius-bottomright: 0;
	-moz-border-radius-bottomleft: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.ft_col ul li a,
.footer_wrap .micro {
	color: rgba(255,255,255,0.7);
}

.ft_top .micro {
	max-width: 27em;
}

.ft_middle {
	margin: 4.375em 0 7.5em;
}

.ft_top .ft_side:last-child,
.ft_middle .ft_side:last-child {
	width: 21.59em;
}

.ft_top .ft_sides {
	align-items: flex-end;
}

.ft_col .label {
	margin-bottom: 1em;
}

.ft_col .qr_set {
	padding-top: 0.6em;
}

.ft_col ul li a {
	padding: 0.5em 0;
	font-size: 1.3em;
}

.ft_social a {
	padding: 0.2em 1em;
}

.ft_social a svg {
	height: 1.6em;
	width: auto;
}

.copyrights ul li {
	position: relative;
	padding: 0 0.5em
}

.copyrights ul li:not(:first-child):before {
	content: '|';
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.copyrights span,
.copyrights ul li:not(:first-child):before {
	color: rgb(255 255 255 / 0.6);
}

.copyrights ul li a {
	padding: 0.2em 0.57em;
}

/* Global */
#site {
	visibility: hidden;
}

.inner_section {
	width: 100%;
	position: relative;
	z-index: 99;
}

.inner_section .button,
.opr_head .button {
	margin-top: 3em;
}

.x_padding {
	padding-left: 7.2916vw;
	padding-right: 7.2916vw;
}

.x2_padding {
	padding-left: 17.1875vw;
	padding-right: 17.1875vw;
}

.x3_padding {
	padding-left: 22.5vw;
	padding-right: 22.5vw;
}

.hidden {
	position: fixed;
	overflow: hidden !important;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

.wrap {
	min-height: 800px;
	text-align: center;
	position: relative;
	background: #ddd;
}

.main_wrap {
	height: 51.5625vw;
}

.has_media {
	overflow: hidden;
}

.inner_wrap {
	height: 41.145833vw;
}

#casestudy .inner_wrap {
	height: auto;
	padding-top: 15em;
	padding-bottom: 13em;
}

.inner_wrap.transparent {
	height: 48.6875em;
	min-height: auto;
	margin-top: 5em;
}

.wrap.black {
	background: #000;
	color: #fff;
}

.inner_wrap.black .inner_section h6,
.inner_wrap.black .inner_section p {
	color: rgba(255,255,255,0.6);
}

.hero_btm {
	position: absolute;
	z-index: 199;
	left: 4.6875em;
	right: 4.6875em;
	bottom: 3.125em;
	align-items: flex-end;
}

.qr_set {
	align-items: flex-end;
}

.qr_code {
	width: 7.3125em;
}

.qr_code img {
	max-width: 100%;
}

.qr_text p {
	display: block;
	line-height: 1.2;
}

.qr_text p:first-child {
	color: rgba(0,0,0,0.6)
}

.isMobile .qr_code {
	display: none;
}

.isMobile .qr_text {
	margin: 0 !important;
	white-space: normal;
	max-width: 15em;
}

.isMobile .app_banner_content .qr_text {
	display: none;
}

.isMobile .qr_set {
	flex-direction: column-reverse;
	align-items: flex-start;
	margin-top: 0 !important;
}

.store_button {
	margin-top: 1.5em;
}

.isDesktop .store_button {
	display: none;
}

.store_button svg {
	width: 13em
}

.isMobile.android .store_button #ios {
	display: none
}

.isMobile.ios .store_button #android {
	display: none
}

footer .store_button svg rect {
	stroke: white;
}

footer .qr_text p:not(:first-child) {
	color: #fff;
}

footer .qr_text p:first-child,
.wrap.black .qr_text p:first-child{
	color: rgba(255,255,255,0.6)
}

.qr_text {
	white-space: nowrap;
}

.support {
	line-height: 1.2;
}

.support a {
	display: inline-block;
}

.support svg {
	width: 2.1875em;
}

.rounded {
	overflow: hidden;
	position: relative;
	-webkit-border-radius: 2.5em;
	-moz-border-radius: 2.5em;
	border-radius: 2.5em;
}

.rounded-all {
	-webkit-border-radius: 40px;
	-moz-border-radius: 40px;
	border-radius: 40px;
}

.rounded-all-less {
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
}

.rounded-top {	
	-webkit-border-top-right-radius: 40px;
	-webkit-border-top-left-radius: 40px;
	-moz-border-radius-topright: 40px;
	-moz-border-radius-topleft: 40px;
	border-top-right-radius: 40px;
	border-top-left-radius: 40px;
}

.rounded-bottom {	
	-webkit-border-bottom-right-radius: 40px;
	-webkit-border-bottom-left-radius: 40px;
	-moz-border-radius-bottomright: 40px;
	-moz-border-radius-bottomleft: 40px;
	border-bottom-right-radius: 40px;
	border-bottom-left-radius: 40px;
}

.head h1,
.head h3,
.head h4 {
	margin-bottom: 0.5em;
}

.head h4 {
	max-width: 51.5625vw;
}

.head .x3 {
	max-width: 24em;
}

.secondary_section {
	background: #fff;
	padding-top: 5.2083vw;
	padding-bottom: 5.2083vw;
	overflow: hidden;
}

.secondary_section.dark {
	background-color: #000;
	color: #fff;
}

.sec_columns > h6,
.sec_columns_head h6 {
	max-width: 39.32vw;
}

.sec_columns_head h6 {
	margin-bottom: 1em;
	margin-top: 0.3em;
}

.sec_column {
	width: 23.875vw;
}

.sec_column_para {
	margin-top: 0.78125em;
}

.double_lines h6 {
	max-width: 16.14583vw;
}

.flex-wrap .sec_column {
	margin-top: 3em;
}

.secondary_section.dark .sec_column_para {
	color: rgba(255,255,255,0.6)
}

.text_sides {
	width: 100%;
	justify-content: space-between
}

.dark .text_sides {
	max-width: 49.21875vw;
	align-items: baseline;
}

.dark .text_side:last-child {
	top: -1.4em;
	position: relative;
}

.text_side h2 {
	max-width: 14.84375vw;
}

.text_side p {
	max-width: 28.6458333vw;
}

.text_side p.wide {
	max-width: 30.2083vw;
}

.text_side h6 {
	max-width: 18vw;
}

.text_side .inline_button {
	margin-top: 1em;
}

.text_side .inline_button .button_content {
	color: #A68DBC;
}

.button {
	display: inline-flex;
	cursor: pointer;
}

.solid_button {
	padding: 1.15em 2.3em;
	border-radius: 100px;
	background: currentColor;
	overflow: hidden;
}

.solid_button.outline {
	background: transparent;
	color: currentColor;
	border: 1px solid currentColor;
}

.header_side .button:hover {
	background: #A68DBC !important;
}

.solid_button .button_content {
	color: #fff;
}

.black .solid_button .button_content {
	color: #000;
}

.numbers .solid_button.outline .button_content {
	color: currentColor;
}

.button_content {
	display: inline-flex;
	align-items: baseline;
	position: relative;
	z-index: 99;
}

.button_content svg {
	position: relative;
	width: 0.8em;
	top: 0.12em;
}

svg.rotated {
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
}

.text_side .inline_button svg {
	width: 1em;
	top: 0.19em;
}

.inline_button:hover svg {
	transform: translateX(0.5em);
	opacity: 0;
}

.inline_button .button_content span,
.inline_button .button_content p {
	position: relative;
}

.isDesktop .inline_button .button_content span:before,
.isDesktop .inline_button .button_content p:before,
.isDesktop .underline:before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 1px;
	background: currentColor;
	transform: scaleX(0);
	transform-origin: 0 0;
}

.isDesktop .inline_button:hover .button_content span:before,
.isDesktop .inline_button:hover .button_content p:before,
.isDesktop .underline:hover:before {
	transform: scaleX(1);
}

.sec_columns > .button {
	margin-top: 2.5em;
}

.small_button {
	position: relative;
	z-index: 199;
	cursor: pointer;
	background: #fff;
	border-radius: 5px;
	padding: 0.45em 0.8em;
	overflow: hidden;
}

.small_button strong {
	letter-spacing: 0.02em;
	display: block;
}

/* Home */

/* Deals */
#deals {
	direction: ltr;
}
.deals_set {
	width: 100vw;
	height: 18.4375em;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	direction: ltr !important;
}

.store {
	width: 22.375em;
	height: 18.4375em;
	position: relative;
}

.isSafari .store article {
	overflow: hidden;
}

.isDesktop:not(.isSafari) .store:not(.preload) article:before {
	filter: none;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	content: '';
	background: #fff;
	border-radius: 20px;
}

.isDesktop:not(.isSafari) .store:not(.preload):hover article {
	transform: translateY(-1em);
}

.isDesktop:not(.isSafari) .store:not(.preload):hover article:before {
	filter: drop-shadow(0px 0px 75px rgba(0, 0, 0, 0.25));
}

.store .store_set {
	overflow: hidden;
}

.deals_col {
	margin: 0 1.0625em;
}

.deals_text {
	margin-top: 2.1875em;
	position: relative;
	padding-bottom: 4.375em;
}

.deals_text h5 {
	margin-bottom: 0.2em;
}

.deals_bg {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 24.875em;
}

.deals_wrap {
	position: relative;
}

.store_cover {
	background: #000;
	height: 13.8125em;
	position: relative;
}

.store_bar {
	height: calc(100% - 13.8125em);
	background: #fff;
	padding: 0 1.25em;
}

.store_off {
	align-items: flex-end;
}

.store_name > div {
	line-height: 1.1;
}

.store_off > div {
	line-height: 1;
}

.store_logo {
	width: 2.625em;
	height: 2.625em;
	border-radius: 50%;
	overflow: hidden;
	position: relative;
	background: #fff;
}

.inner_head {
	margin-bottom: 2.5em;
}

/* featured Stores */
.featured_store {
	width: 30.125em;
	height: 18.5625em;
	background: #fff;
	position: relative;
	overflow: hidden;
}

.featured_cover i:not(.ready) {
	transform: none !important;
}

.featured_cover i.ready {
	left: -4em;
	right: -4em;
}

.overlay_cover:before,
.overlay_cover:after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 99;
	opacity: 0
}

.overlay_cover:after {
	height: 50%;
	background: rgba(0,0,0,1);
	background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,1)), color-stop(100%, rgba(0,0,0,0)));
	background: -webkit-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
	background: -o-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
	background: -ms-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
	background: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
}

.overlay_cover:before {
	bottom: 0;
	background: rgba(0,0,0,0);
	background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,1)));
	background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
	background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
	background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
}

.featured_details {
	z-index: 699;
}

.featured_logo {
	position: relative;
	width: 4.375em;
	height: 4.375em;
	border-radius: 50%;
	overflow: hidden;
}

.featured_bar {
	position: absolute;
	left: 1.875em;
	right: 1.875em;
	bottom: 1.875em;
	color: #fff;
	align-items: flex-end;
}

.featured_name {
	position: absolute;
	color: #fff;
	right: 1.875em;
	top: 1.875em;
	overflow: hidden;
}

.featured_text {
	overflow: hidden;
}

.isMobile .featured_name p,
.isMobile .featured_text p {
	transform: translateY(100%);
}

.isDesktop article:not(.preload):hover .overlay_cover:before,
.isDesktop article:not(.preload):hover .overlay_cover:after,
.isMobile .is-selected article:not(.preload) .overlay_cover:before,
.isMobile .is-selected article:not(.preload) .overlay_cover:after {
	opacity: 0.3;
}

.isDesktop .featured_store:hover p,
.isMobile .is-selected .featured_store p {
	transform: translateY(0);
}

.circle_button {
	width: 2.5em;
	height: 2.5em;
	position: relative;
	border-radius: 50%;
	z-index: 199;
}

.circle_button:before {
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	position: absolute;
	background: #fff;
	border-radius: 50%;
	transform: scale(0);
}

.circle_button svg {
	width: 0.710625em;
	position: relative;
	opacity: 0;
	transform: translate(-100%, 100%);
	mix-blend-mode: exclusion;
}

.circle_button svg path {
	fill: #fff !important;
}

article .small_button {
	opacity: 0;
	transform: translateY(50%);
}

.isDesktop article:hover .circle_button:before,
.isMobile .stores_col .circle_button:before,
.isMobile .is-selected article .circle_button:before {
	transform: scale(1);
	opacity: 1;
}

.isMobile .stores_col .circle_button svg,
.isDesktop article:hover .circle_button svg,
.isMobile .is-selected article .circle_button svg,
.isDesktop article:hover .small_button,
.isMobile .is-selected article .small_button {
	transform: translate(0);
	opacity: 1;
}

.isDesktop .circle_button:hover:before,
.isMobile .is-selected .circle_button:before {
	transform: scale(1.3) !important;
}

.featured_cta {
	position: relative;
	width: 30.125em;
	margin-top: 4.375em;
}

.featured_info {
	max-width: 21.25em;
}

.featured_info .button {
	margin-top: 1em;
}

/* Banner */
.banner_wrap {
	text-align: center;
	height: 41.6667vw;
	min-height: 600px;
}

.banner_wrap.shorter {
	height: 35vw;
	min-height: 450px;
}

.banner_set {
	height: 100%;
	position: relative;
	max-width: 57em;
}

.banner_set .button {
	margin-top: 2em;
}

/* Store Directory */
#shop #fixed-bar > div[data-scroll] {
	position: relative;
	z-index: 699;
}

.stores_wrap {
	position: relative;
	z-index: 99;
}

.bar_set {
	height: 7.5625em;
	background: #fff;
	border: 1px solid rgba(0,0,0,0.3);
	overflow: hidden;
}

.inner_bar .bar_set {
	border: none !important;
}

.bar_wrap {
	margin-top: calc(-7.8125vw + -7.5625em - 3.125em - 2.34375vw );
	padding-top: 2.34375vw;
	overflow: hidden;
	position: relative;
}

.stores_section {
	margin-top: 12.5em;
	min-height: calc(var(--vh,1vh) * 80);
}

.bar_search {
	position: absolute;
	height: 100%;
	width: 38.875em;
	top: 0;
	left: 0;
}

.bar_search input {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	appearance: none;
	border: none;
	outline: none;
	background: transparent;
	font-size: 2.5em;
	font-weight: 300;
}

.search_icon {
	position: absolute;
	pointer-events: none;
}

.search_icon svg {
	width: 2.5em;
}

.filter {
	white-space: nowrap;
	padding: 1em;
	cursor: not-allowed;
	color: rgba(0,0,0,0.5);
	position: relative;
}

.inner_bar .filter {
	padding-left: 1.8em;
	padding-right: 1.8em;
}

.filter:before {
	content: '';
	position: absolute;
	width: 1.3125em;
	height: 3px;
	background: black;
	top: -1.8em;
	left: 50%;
	transform: translateX(-0.65625em);
	opacity: 0;
}

.bar_filters .filter:before {
	top: -1.1em;
}

.filter.active:before {
	opacity: 1;
}

.bar_filters:not(.disabled) .filter:not(.active) {
	cursor: pointer;
}

.bar_filters:not(.disabled) .filter:hover {
	color: rgba(0,0,0,0.8);
}

.bar_filters.disabled .filter {
	color: rgba(0,0,0,0.3);
}

.bar_filters:not(.disabled) .filter.active {
	cursor: default;
	color: #000;
}

.bar_filters {
	width: 100%;
	padding: 0 1em;
}

.bar_side {
	position: relative;
	overflow: hidden;
}

.bar_side:first-child {
	width: 38.875em;
}

.bar_side:last-child {
	flex: 1 0 0;
}

.bar_side:first-child {
	overflow: hidden;
	background: #fff;
}

.stores_col {
	margin-top: 2.5em;
}

.stores_row:not(.has_slider) .stores_cols {
	width: calc(100% + 2.4375em);
}

.stores_cols {
	flex-wrap: wrap;
}

.stores_cols .featured_store {
    margin-top: 2.5em;
}

.stores_row:not(:first-child),
.articles_row:not(:first-child) {
    margin-top: 6.25em;
}

/* About */
.abt_imgs_wrap {
	position: absolute;
	width: 141.875em;
	left: 50%;
	transform: translateX(-50%);
	top: 0;
	bottom: 0;
}

.abt_imgs_side {
	width: 41.875em;
	height: 100%;
}

.abt_imgs_side:first-child .abt_img:first-child,
.abt_imgs_side:last-child .abt_img:first-child {
	z-index: 99;
}

.abt_imgs_side:first-child .abt_img:first-child,
.abt_imgs_side:last-child .abt_img:last-child {
	bottom: 0;
}

.abt_img {
	position: absolute;
	width: 22.5em;
	height: 43.375em;
}

.opr_row {
	width: 61.875em;
}

.opr_row h6 {
	max-width: 80%;
	margin-bottom: 0.7em;
}

.opr_row:not(:first-child) {
	margin-top: 3.75em;
}

.opr_col {
	width: 26.25em;
}

.opr_head h5 {
	margin-bottom: 0.3em;
}

.team_wrap {
	max-width: 46.875em;
}

.team_text {
	margin: 2.5em 0;
}

.team_text p {
	max-width: 80%;
	margin-top: 1em;
}

.team_img:first-child {
	width: 40em;
	height: 30em;
}

.team_img:not(:first-child) {
	position: absolute;
}

.team_imgs {
	width: 100%;
	height: 43.125em;
	align-items: flex-end;
	position: relative;
	margin-top: 3.125em;
}

.team_img:nth-child(2) {
	width: 25.625em;
	height: 32.6875em;
	top: 0;
	z-index: 99;
}

.team_img:nth-child(3) {
	bottom: 0;
	width: 23.875em;
	height: 18.0625em;
}

/* Careers */
.careers_media_wrap {
	position: absolute;
	width: 80em;
	height: 40em;
	padding-top: 5em;
	pointer-events: none;
}

.careers_media {
	width: 12.5em;
	height: 12.5em;
	position: absolute;
}

.careers_media:nth-child(2) {
	bottom: 3.125em;
}

.careers_media:nth-child(3) {
	top: 7.1875em;
}

.careers_media:nth-child(4) {
	bottom: 0;
}

.careers_media_set {
	background: rgba(255,255,255,0.15);
	position: absolute;
}

.careers_media_set video {
	width: auto;
	height: 100%;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	opacity: 0;
}

/* Blog */
.inner_bar {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 3.125em;
	overflow: hidden;
	color: #000;
}

.article_box {
	overflow: hidden;
	margin-bottom: 2.4375em;
}

.article_box > a {
	min-height: 100%;
	display: flex;
}

.article {
	width: 30.625em;
	background: #fff;
}

.article.wide {
	width: 47.2em;
}

.article_cover {
	position: relative;
	padding-top: 62%;
}

.articles_set {
	flex-wrap: wrap;
	width: calc(100% + 2.4375em);
}

.article_bar {
	padding: 2em
}

.article_bar h6 {
	margin: 0.4em 0;
}

.article_bar p {
	color: rgba(0,0,0,0.6);
}

.article_bar p {
	max-width: 78%;
	max-height: 5em;
	overflow: hidden;
}

/* Article */
.article_media img,
.article_media video,
.article_media iframe {
	max-width: 100%;
}

.article_section {
	align-items: flex-end;
	position: relative;
}

.article_media {
	position: relative;
}

.article_body p:not(.sec_column_para) {
	margin-top: 1em;
	max-width: 90%;
	line-height: 1.7;
}

.article_body h5:not(:first-child) {
	margin-top: 1em;
}

.article_body h5 {
	max-width: 70%;
}

.article_body p a {
	display: inline-block;
	color: #A68DBC;
}

.article_media {
	margin-top: 4em;
}

.article_media {
	margin-bottom: 4em
}

.article_head {
	max-width: 85%;
}

.article_head h5 {
	margin: 1em 0 0.6em;
}

.article_head h6 {
	max-width: 80%;
	margin-bottom: 0.6em;
}

.quote {
	margin-top: 4em;
	padding: 5em 7em;
	background: #E49276;
	-webkit-border-top-left-radius: 30px;
	-webkit-border-top-right-radius: 30px;
	-moz-border-radius-topleft: 30px;
	-moz-border-radius-topright: 30px;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
}

.quote p {
	width: 100%;
	max-width: 100% !important;
}

.quote svg {
	width: 5em;
	margin-bottom: 3em;
	opacity: 0.1;
}

.quote p:last-child {
	line-height: 1.5;
	opacity: 0.7;
}

.share_set {
	position: absolute;
	z-index: 99;
	text-align: center;
}

.share_icons {
	flex-direction: column;
}

.share_icons a {
	margin-top: 0.9375em;
}

.share_icons a {
	width: 3.875em;
	height: 3.875em;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
}

.share_icons a:hover {
	opacity: 0.5
}

.fb {
	background: #3B5998;
}

.tw {
	background: #00ACEE;
}

.in {
	background: #0E76A8;
}

.insta {
	background: #CD486B;
}


/* Press */
.hero_btm.align-end .hero_side:nth-child(1) {
	max-width: 27em;
}

.hero_btm.align-end .hero_side:nth-child(2) {
	max-width: 17em;
}

.articles_head {
	margin-bottom: 2.5em;
}

.hero_btm.align-end a {
	color: #A68DBC;
	display: inline-flex;
}

.download {
	align-items: center;
}

.download svg {
	width: 0.7em;
}

/* App */
.htb_section {
	padding: 4em 0;
}

.htb_card {
	padding: 4.375em;
	width: 31.875em;
	color: #000;
	position: relative;
}

.htb_card h5 {
	width: 100%;
}

.htp_block {
	margin-top: 2.5em;
}

.htb_head {
	margin-bottom: 6em;
}

.app_head {
	position: absolute;
	top: calc(var(--vh,1vh) * 6.389);
}

.app_section > .app_head {
	display: none;
}

.app_phone {
	position: relative;
	width: calc(var(--vh,1vh) * 40.277);
	pointer-events: none;
}

.app_phone_screen {
	top: 1.5%;
	right: 3.5%;
	bottom: 1.5%;
	left: 3.5%;
	overflow: hidden;
	position: absolute;
	border-radius: calc(var(--vh,1vh) * 4);
	overflow: hidden;
}

.as.stagger {
	clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
}

.as i {
	height: calc(var(--vh,1vh) * 84);
	top: 0;
	left: 0;
	right: 0;
	position: absolute;
}

.app_phone > svg {
	width: 100%;
	z-index: 99;
	position: relative;
}

.app_phone > svg path.fill {
	fill: currentColor;
}

.app_fixed {
	position: absolute !important;
	height: calc(var(--vh,1vh) * 100);
	top: 0;
	left: 0;
	right: 0;
	perspective: 1px;
}

.app_section {
	position: relative;
	margin-top: calc(var(--vh,1vh) * -6.389);
}

.app_content {
	width: 100%;
	max-width: calc(var(--vh,1vh) * 85);
	position: relative;
	align-items: center;
}

.app_content_wrap {
	height: calc(var(--vh,1vh) * 100);
}

.app_content_wrap:nth-child(odd) .app_content {
	flex-direction: row-reverse;
}

.card_details {
	padding: 3.125em 2em;
	position: relative;
	width: 22.5em;
	z-index: 999;
}

.card_details > p {
	margin: 0.8em 0;
}

.card_details > h4 {
	margin-top: 0.75em;
}

.card_photo {
	width: 22.5em;
	padding-top: 22.5em;
	position: absolute;
	background: #000;
}

.card_photo i {
	top: -45%;
	bottom: -45%;
}

.app_content_wrap:nth-child(even) .card_photo {
	transform: rotate(5deg);
}

.app_content_wrap:nth-child(odd) .card_photo {
	transform: rotate(-5deg);
}

html[lang='ar'] .app_content_wrap:nth-child(even) .card_photo {
	transform: rotate(-5deg);
}

html[lang='ar'] .app_content_wrap:nth-child(odd) .card_photo {
	transform: rotate(5deg);
}

html:not(.has-scroll-smooth) .app_section {
	margin-top: 0
}

html:not(.has-scroll-smooth) .app_phone {
	height: auto;
}

html:not(.has-scroll-smooth) .app_head {
	position: relative;
	top: 0;
	transform: none;
}
html:not(.has-scroll-smooth) .app_fixed {
	transform: none !important;
	display: none;
}
html:not(.has-scroll-smooth) .app_content_wrap {
	height: auto;
}

html:not(.has-scroll-smooth) .app_content {
	max-width: unset;
	display: inline-flex;
	margin-top: 4em;
	align-items: flex-start;
}

html:not(.has-scroll-smooth) .card_details {
	flex: 1;
	max-width: calc(100% - 22.5em);
}

html:not(.has-scroll-smooth) .card_details,
html:not(.has-scroll-smooth) .card_photo {
	margin-top: 0;
	transform: none !important;
}

html:not(.has-scroll-smooth) .card_photo {
	padding-top: 0;
	flex: 1;
	height: 100%;
	z-index: 1999;
}

html:not(.has-scroll-smooth) .app_content_wrap:nth-child(even) .card_photo {
	-webkit-border-top-left-radius: 0;
	-webkit-border-bottom-left-radius: 0;
	-moz-border-radius-topleft: 0;
	-moz-border-radius-bottomleft: 0;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

html:not(.has-scroll-smooth) .app_content_wrap:nth-child(odd) .card_photo {
	-webkit-border-top-right-radius: 0;
	-webkit-border-bottom-right-radius: 0;
	-moz-border-radius-topright: 0;
	-moz-border-radius-bottomright: 0;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.faqs {
	padding: 4em 0;
}

/* App Banner */
.app_banner_wrap {
	position: relative;
	height: 63.875em;
	overflow: hidden;
}

.app_banner_hand {
	height: 63.875em;
	z-index: 99;
	pointer-events: none;
}

.app_banner_hand {
	transform: translateY(20%);
}

.app_banner_set {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 50em;
}

.minimal {
	height: 50em;
	overflow: hidden;
}

.app_banner_circle {
	position: absolute;
	width: 44.875em;
	height: 44.875em;
	background: #fff;
	border-radius: 50%;
	top: -2.5em;
	transform: scale(0);
}

.app_banner_content {
	position: absolute;
	bottom: 7.5em;
	max-width: 41em;
}

.app_banner_content h4 {
	margin-bottom: 0.2em;
}

.app_banner_content .qr_set {
	margin-top: 3em;
}

.app_banner_content p {
	margin-top: 0.3em;
}


/* Deals Side */
#asideScroller {
	perspective: 1px;
}

aside {
	height: calc(var(--vhS, 1vh) * 100);

}
aside [data-scroll-container] 	{
	min-height: calc(var(--vhS, 1vh) * 100);
}

aside {
	position: fixed;
	z-index: 2999;
	top: 0;
	width: 100%;
	visibility: hidden;
}

.deals_container {
	position: relative;
	display: flex;
	justify-content: flex-end;
	width: 100%;
	overflow: hidden;
	height: 100%;
}

.deals_side {
	width: 46.875em;
	position: relative;
	height: 100%;
}

.deal_cover {
	position: absolute;
	overflow: hidden;
	background: #000;
	height: 28.8125em;
	top: 0;
	right: 0;
	left: 0;
}

.deal_content {
	padding-top: 26em;
	position: relative;
	z-index: 199;
}

.deal_body {
	background: #fff;
	padding: 2.34375vw;
	padding-top: 4.375em;
	position: relative;
}

.deal_content:before {
	content: '';
	position: absolute;
	top: 100%;
	height: 500px;
	left: 0;
	right: 0;
	background: #fff;
}

.ds_logo {
	width: 6.25em;
	height: 6.25em;
	border-radius: 50%;
	position: relative;
	overflow: hidden;
	border: 1px solid rgba(0,0,0,0.2);
}

.ds_text {
	margin: 2em 0;
	margin-bottom: 3em;
	padding-bottom: 3em;
	border-bottom: 1px solid rgba(0,0,0,0.2);
}

.ds_text h6 {
	margin-bottom: 0.3em;
}

.ds_text p {
	margin-bottom: 0.8em;
}

.ds_date {
	color: #EFA91C;
	background: #FDF6E8;
	display: inline-flex;
	padding: 0.5em 0.8em;
}

.ds_date svg {
	width: 1.0625em;
}

.side_button {
	height: 4.875em;
}

.promo {
	position: relative;
	margin-top: 1.25em;
}

.copy {
	width: 3.3125em;
	height: 3.3125em;
	background: #fff;
	border-radius: 50%;
	position: absolute;
	cursor: pointer;
	color: #fff;
	overflow: hidden;
}

.isDesktop .copy:hover,
.isMobile .copy:hover {
	background: #000;
}

.copy.disabled {
	background: #CEF7D2 !important;
	color: #000;
}

.copy:not(.disabled) svg {
	mix-blend-mode: exclusion;
}

.copy svg {
	width: 1.21875em;
}

.action_button {
	background: #000;
	color: #fff;
}

.action_button svg {
	width: 1em;
}

.side_faq_set {
	margin: 4em 0;
}

.faq_block:not(:first-child) {
	border-top: 1px solid rgba(0,0,0,0.3);
}

.faq_block .faq_head {
	padding-top: 2em;
	padding-bottom: 2em;
}

.faq_body {
	margin-bottom: 2em;
	display: none;
}

.faq_body p:not(:first-child),
.faq_body ul:not(:first-child) {
	margin-top: 1em;
}

.faq_content {
	max-width: 70%;
	visibility: hidden;
}

.faq_body ol {
	list-style: disc;
	display: list-item;
	padding-left: 0;
	padding-right: 0;
}

.faq_head {
	cursor: pointer;
}

.faq_head h6 {
	max-width: 70%;
}

.faq_head svg {
	width: 0.875em;
}

.faq_block.active svg,
nav ul li.active svg {
	transform: rotate(-180deg);
}

.close_side {
	position: absolute;
	z-index: 299;
	cursor: pointer;
	top: 2.34375vw;
}

.close_side:hover {
	transform: rotate(90deg);
}

.close_side svg {
	width: 1.9375em;
}

/* Mega Menu */
.mega_menu_wrap {
	position: absolute;
	top: 0;
	left: -1px;
	right: -1px;
	height: 35.625em;
	overflow: hidden;
	visibility: hidden;
}

.mega_menu_wrap > i {
	 background: #fff;
	 transform-origin:  0 0;
	 transform: scaleY(0);
}

.mega_menu {
	top: 10em;
	border-top: 1px solid rgba(0,0,0,0.2);
	visibility: hidden;
}

.mega_menu_side:first-child {
	flex: 40em 0 0;
	padding: 3.75em;
	border-right: 1px solid rgba(0,0,0,0.2);
}

.mega_menu_side {
	padding-top: 3.75em;
	padding-bottom: 3.75em;
	width: 100%;
	overflow: hidden;
}

.mega_cats {
	flex-wrap: wrap;
	margin-top: 1.2em;
}

.mega_cats:hover a {
	color: rgba(0,0,0,0.4)
}

.mega_cats a:hover {
	color: rgba(0,0,0,1)
}

.mega_cats a {
	width: 14.7em;
	position: relative;
	padding: 1.1em 0;
	align-items: center;
}

.mega_cats a:not(:last-child) svg {
	width: 1.4em;
}

.mega_cats a:last-child svg {
	width: 0.8em;
	opacity: 0;
}

.mega_cats a:last-child:hover svg {
	opacity: 1;
}

html[lang=en] .mega_cats a:last-child svg {
	transform: translateX(100%);
}

html[lang=en] .mega_cats a:last-child:hover svg {
	transform: translateX(0%);
}

html[lang=ar] .mega_cats a:last-child svg {
	transform: translateX(-100%) scaleX(-1);
}

html[lang=ar] .mega_cats a:last-child:hover svg {
	opacity: 1;
	transform: translateX(0%) scaleX(-1);
}


.mega_cats a:before,
.mega_cats a:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 1px;
	background: rgba(0,0,0,0.2);
}

.mega_cats a:after {
	background: rgba(0,0,0,1);
	transform-origin: 0 0;
	transform: scaleX(0)
}

.mega_cats a:hover:after {
	transform: scaleX(1)
}

.ms_col {
	margin-top: 1.875em;
}

.ms_col .store_cover {
	height: 10.9375em;
}

.ms_col .store {
	width: 17.75em;
	height: 14.4375em;
}

.ms_col .store_bar {
	background: #000;
	color: #fff;
	height: calc(100% - 10.9375em);
}

.ms_col .store_name {
	transform-origin: left center;
	transform: scale(0.8);
}

.ms_col .store_off {
	transform-origin: right center;
	transform: scale(0.9);
}

.ms_col .store_bar .store_logo {
	width: 2.1875em;
	height: 2.1875em;
}

/* Partners */
.figure {
	margin-top: 3em;
	text-align: center;
	flex: 19.375em 0 0;
}

.figure p {
	max-width: 80%
}

.figure_head {
	align-items: baseline;
}

.figures {
	margin-top: 3em;
}

#casestudy .figures {
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;
	margin-top: 0;
}

.int_cols {
	width: 46.25em;
	flex-wrap: wrap;
}

.int_col {
	margin-bottom: 2.5em;
}

.int_box {
	width: 13.75em;
	height: 13.75em;
	background: #fff;
}

.int_box svg {
	height: 4.863125em;
	margin-bottom: 1.625em;
}

.opr_head p {
	margin-top: 1.2em;
	max-width: 21em;
}

.sec_logos img {
	height: auto;
	width: 100%;
	margin-top: 2em;
}

.sec_logos {
	flex-wrap: wrap;
	width: 100%;
}

.sec_logo {
	width: 16.66%;
}


.map_set {
	position: relative;
	height: 46.25em;
	min-height: 500px;
	overflow: hidden;
}

.map_set > i {
	background-position: 70% center;
}

.map_content {
	position: relative;
}

.map_head {
	max-width: 32em;
	margin-bottom: 2em;
}

.map_head h3 {
	margin-bottom: 0.3em;
}

.map_body span {
	display: block;
}

.store.preload,
.featured_store.preload {
	color: #c3c3c3;
}

.featured_store.preload {
	background: currentColor;
}

.featured_store.preload .featured_logo {
	background: #999;
}

.preload .store_name {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.preload .store_name > div {
	background: currentColor;
}

.preload .store_name > div:last-child {
	margin-top: 2px;
}

.preload .store_cover,
.preload .store_logo {
	background: currentColor;
}


/* Colors */
.light-orange {
	background: #F1C299;
}

.pink {
	background: #E7A0A9;
}

.sky {
	background: #CEE5F7;
}

.purple {
	background: #A68DBC;
}

.orange {
	background: #E49276;
}

.yellow {
	background: #FADD91;
}

.transparent {
	background: transparent;
}

/* Cases */
#casestudy .wrap h3 {
	max-width: 69vw;
	/* margin-bottom: 0.3em; */
}

.case_cta {
	text-align: center;
	padding: 5em 2em;
	background: #CEE5F7;
	margin: 4em 0;
	min-height: 30em;
	border-radius: 30px;
}

.case_cta h4 {
	max-width: 50%;
	margin-bottom: 0.5em;
}

.numbers {
	background: #A68DBC;
	-webkit-border-bottom-right-radius: 30px;
	-webkit-border-bottom-left-radius: 30px;
	-moz-border-radius-bottomright: 30px;
	-moz-border-radius-bottomleft: 30px;
	border-bottom-right-radius: 30px;
	border-bottom-left-radius: 30px;
	padding: 4em 0;
	padding-top: 3em;
	flex-direction: column;
	overflow: hidden;
}

#casestudy .figure:nth-child(4) {
	flex: 20em 0 0;
}

#casestudy .figure:nth-child(5) {
	flex: 25em 0 0;
}

.inner_cta a {
	margin: 0 0.7em;
}

.inner_cta {
	margin-top: 5em;
}

.quote_media {
	width: 100em;
	padding-top: 2em;
}

.quote_media img {
	max-width: 100%;
}

.article_text {
	max-width: 80%;
	margin: 0 auto;
}

.sec_columns h4 {
	margin-bottom: 0.4em;
}

.sec_columns p {
	max-width: 37em;
}

.sub_logo {
	position: absolute;
	z-index: 99;
	width: 3em;
	top: 9em;
}

.sub_logo img {
	width: 100%;
}

.farah_phone .app_phone {
	color: #101010;
	position: absolute;
	z-index: 199;
}

#farahWrap {
	margin-top: -13em;
	height: 400vh;
	margin-bottom: 0;
	position: relative;
}

#farahWrap .pin-spacer:last-child {
	z-index: 199 !important;
	pointer-events: none;
}

#farah .app_phone_screen {
	background: currentColor;
}

.farah_phone {
	height: 100vh;
	display: flex;
	align-items: center;
}

.farah_content {
	top: 15em;
	padding-top: 5em;
	position: absolute;
}

.f_card {
	background: #fbfbfb;
	padding: 2.2em;
	width: 21em;
	border-radius: 30px;
	flex-shrink: 0;
}

.f_card p {
	margin-top: 1.5em;
}

.f_cards {
	margin-top: 10em;
	align-items: flex-start;
}

#screen5 {
	z-index: 199;
}


/* Responsiveness */
@media (min-width: 1201px){
	header.light.opened {
		color: #000;
	}
	header.light.opened .button_content {
		color: #fff;
	}
	.light.opened .breadcrumb span {
		color: rgba(0,0,0,0.6)
	}
}

@media (max-width: 1200px){
	body {
		font-size: 1.3vw;
	}
	h1 {
		font-size: 6.5em;
	}
	h2 {
		font-size: 5.5em;
	}
	h3 {
		font-size: 5em;
	}
	h4 {
		font-size: 3em;
	}
	h5 {
		font-size: 2.2em;
	}
	h6 {
		font-size: 1.8em;
	}
	p,
	ol {
		font-size: 1.4em;
	}
	.label {
		font-size: 1.1em;
	}
	.micro {
		font-size: 0.8em;
	}
	section,
	header {
		margin-top: 24px;
	}
	.footer_wrap {
		padding-bottom: 24px;
	}
	header {
		padding: 40px 4.6875em;
	}
	nav,
	.header_side .button,
	.mega_menu_wrap {
		display: none !important;
	}
	.rounded-all{
		-webkit-border-radius: 30px;
		-moz-border-radius: 30px;
		border-radius: 30px;
	}
	.rounded-top {	
		-webkit-border-top-right-radius: 30px;
		-webkit-border-top-left-radius: 30px;
		-moz-border-radius-topright: 30px;
		-moz-border-radius-topleft: 30px;
		border-top-right-radius: 30px;
		border-top-left-radius: 30px;
	}
	.rounded-bottom {	
		-webkit-border-bottom-right-radius: 30px;
		-webkit-border-bottom-left-radius: 30px;
		-moz-border-radius-bottomright: 30px;
		-moz-border-radius-bottomleft: 30px;
		border-bottom-right-radius: 30px;
		border-bottom-left-radius: 30px;
	}
	.wrap:not(.transparent) .head > p {
		max-width: 70% !important;
	}
	.hero_btm {
		bottom: 40px;
	}
	.hero_btm.align-end {
		padding: 0 !important;
	}
	.x2_padding {
		padding-left: 6em;
		padding-right: 6em;
	}
	.secondary_section {
		padding-top: 4em;
		padding-bottom: 4em;
	}
	.double_lines h6 {
		max-width: 100%;
	}
	.sec_row.flex-wrap .sec_column {
		width: 36vw;
	}
	.sec_columns > h6,
	.sec_columns_head h6 {
		max-width: 80%;
	}
	.text_sides {
		width: 90%;
	}
	.text_side h6 {
		max-width: 12em;
	}
	.text_side p {
		max-width: 19em;
	}
	.text_side p.wide {
		max-width: 90%;
		margin-top: 1em;
	}
	.dark .text_sides {
		max-width: 100%;
		flex-direction: column;
	}
	.text_side h2 {
		max-width: 100%;
	}
	.featured_store {
		width: 29.8em;
		height: 26.396em;
	}
	.featured_cta {
		width: auto;
		justify-content: flex-start;
	}
	.stores_col .store {
		width: 29.8em;
		height: 24.555em;
	}
	.stores_col .store_cover {
		height: 18.396em;
	}
	.stores_col .store_bar {
		height: calc(100% - 18.396em);
	}
	.store_logo {
		width: 3.5em;
		height: 3.5em;
	}
	.abt_imgs_wrap {
		width: 135%
	}
	.inner_wrap.transparent {
		height: 37.6875em;
	}
	.abt_img {
		width: 17.41625em;
		height: 33.575em;
	}
	.opr_wrap {
		flex-direction: column;
	}
	.opr_row {
		width: 100%;
	}
	.team_wrap {
		max-width: 100%;
	}
	.team_wrap h6 {
		max-width: 80%
	}
	.article {
		width: 19em;
	}
	.article.wide {
		width: 29.8em;
	}
	.article_bar h6 {
		font-size: 1.3em;
		margin-top: 0.4em;
	}
	.article p {
		display: none;
	}
	.hero_btm.align-end .hero_side:nth-child(1) {
		max-width: 29em;
	}
	.hero_btm.align-end .hero_side:nth-child(2) {
		max-width: 18em;
	}
	.card_details {
		padding: 2em;
	}
	html:not(.has-scroll-smooth) .card_details {
		padding: 4em;
	}
	.app_section > .app_head {
		display: block;
	}
	.app_fixed > .app_head {
		display: none;
	}
	html.has-scroll-smooth .app_head {
		position: relative;
		top: 0;
		transform: translateY(-100%);
	}
	html.has-scroll-smooth .app_section {
		margin-top: calc(9em + 7.8125vw);
	}
	.app_banner_content {
		max-width: 35em;
		bottom: 5em;
	}
	.mega_menu {
		top: 6.8em;
	}
	.bar_side:first-child {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		z-index: 199;
		width: 7em;
	}
	.bar_filters .filter:before {
		top: -0.9em;
	}
	.opened .bar_side:first-child {
		width: 100%;
	}
	.figures {
		flex-wrap: wrap;
		margin-top: 1em;
	}
	.figure {
		flex: 50% 0 0 !important;
	}
	.opr_head {
		transform: none !important;
	}
	.opr_head p {
		max-width: 70%;
	}
	.int_box {
		width: 19em;
	}
	.int_cols {
		width: 100%;
	}
	.sec_logo {
		width: 33.33%;
	}
	.burger_set {
		display: block;
	}
	.burger_content .ft_col .label {
		font-size: 1.6em;
		margin-bottom: 0.5em;
	}
	.burger_content .ft_col a {
		font-size: 1.2em;
	}
	.article_body {
		padding: 0 !important;
	}
	.article_text {
		max-width: 100%;
	}
}

@media (max-width: 1200px) AND (min-width: 768px){
	#farah .app_banner_circle {
		left: 35%;
		top: 0;
	}
}

@media (max-height: 700px) AND (min-width: 1600px){
	.app_content {
		max-width: calc(var(--vh,1vh) * 120);
	}
}


@media (max-width: 1200px) AND (min-width: 641px){
	.sec_row:not(.flex-wrap) {
		display: block;
	}
	.sec_row:not(.flex-wrap) .sec_column {
		width: 32vw;
	}
	.opr_head {
		margin-bottom: 3em;
	}
}

@media (min-width: 769px){
	.f_cards .app_phone {
		display: none;
	}
	.f_card:not(:first-child) {
		opacity: 0.2;
	}
	#farah .app_banner_hand {
		display: none;
	}
}

@media (max-width: 768px){
	#farah .app_banner_hand {
		/*transform: translateY(0);*/
	}
	#farahWrap {
		margin-top: 7.8125vw;
		height: auto;
		margin-bottom: 7.8125vw;
	}
	.f_cards {
		flex-direction: column;
		align-items: center;
		margin-top: 3em;
	}
	.farah_phone {
		height: unset;
		justify-content: center;
		width: 70%;
		margin-top: -22em;
	}
	.app_phone_screen {
		border-radius: 2em;
	}
	#farahPhone {
		display: none;
	}
	.farah_phone .app_phone {
		position: relative;
		left: unset !important;
		right: unset !important;
		width: 100%;
	}
	.farah_content {
		top: 0;
		padding-top: 0;
		position: relative;
	}
	.f_card {
		width: 100%;
		border-radius: 20px;
		background: #fff;
		padding: 4em;
		padding-bottom: 25em;
		opacity: 1 !important;
	}
	.f_card:not(:first-child) {
		margin-top: 10em;
	}
}

@media (min-width: 641px){
	html:not(.has-scroll-smooth) .app_content_wrap:nth-child(odd) .card_details {
		-webkit-border-top-left-radius: 0;
		-webkit-border-bottom-left-radius: 0;
		-moz-border-radius-topleft: 0;
		-moz-border-radius-bottomleft: 0;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
	html:not(.has-scroll-smooth) .app_content_wrap:nth-child(even) .card_details {
		-webkit-border-top-right-radius: 0;
		-webkit-border-bottom-right-radius: 0;
		-moz-border-radius-topright: 0;
		-moz-border-radius-bottomright: 0;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
}

@media (max-width: 640px){
	section,
	footer {
		margin-top: 0;
	}
	section {
		margin-bottom: 50px
	}
	body {
		font-size: 2.7vw;
	}
	h1 {
		font-size: 4.9em;
	}
	h2 {
		font-size: 4.2em;
		line-height: 100%;
	}
	h3 {
		font-size: 3.5em;
	}
	h6 {
		font-size: 1.65em;
	}
	header {
		margin: 0;
		padding: 30px;
		border-radius: 0;
	}
	.x_padding,
	.x2_padding {
		padding-left: 30px !important;
		padding-right: 30px !important;
	}
	.breadcrumb_wrap {
		display: none;
	}
	.solid_button {
		padding: 1em 2em;
	}
	.inner_section .button,
	.opr_head .button {
		margin-top: 2em;
	}
	.inner_section {
		padding: 0 30px;
	}
	.footer_wrap {
		padding-bottom: 0;
	}
	.hero_btm {
		left: 30px;
		right: 30px;
		bottom: 30px;
	}
	.hero_btm {
		bottom: 24px;
	}
	.hero_btm.align-end {
		flex-direction: column;
		align-items: flex-start;
	}
	.hero_btm.align-end p {
		font-size: 1.2em;
		color: rgba(255,255,255,0.8)
	}
	.hero_btm.align-end .hero_side:nth-child(1) {
		margin-bottom: 1em;
	}
	.hero_btm.align-end .hero_side {
		max-width: unset !important;
		width: 100% !important;
	}
	.rounded {
		-webkit-border-radius: 15px;
		-moz-border-radius: 15px;
		border-radius: 15px;
	}
	.rounded-all,
	.rounded-top,
	.rounded-bottom {
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
	}
	.rounded-all-less,
	.isDesktop:not(.isSafari) .store article:before {
		-webkit-border-radius: 15px;
		-moz-border-radius: 15px;
		border-radius: 15px;
	}
	.head br,
	.support svg {
		display: none;
	}
	.head p {
		max-width: 100% !important;
	}
	.support span {
		display: flex;
		flex-direction: column;
		align-items: flex-start
	}
	.qr_code {
		width: 7em;
	}
	.wrap .inner_section .head > h6,
	.wrap .inner_section .head > p {
		margin-top: 0;
	}
	.secondary_section {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.sec_row,
	.text_sides {
		flex-direction: column
	}
	.sec_column {
		width: 100% !important;
	}
	.sec_column,
	.banner_set {
		max-width: 100%;
	}
	.sec_column:not(:first-child) {
		margin-top: 3em;
	}
	.sec_column_para {
		max-width: 85%;
	}
	body:not(#casestudy) .sec_column_para br {
		display: none;
	}
	.sec_columns > h6,
	.sec_columns_head h6 {
		max-width: 100%;
	}
	.text_sides {
		width: 100%;
	}
	.text_side h6 {
		max-width: 80%;
	}
	.text_side p {
		max-width: 100% !important;
		margin-top: 1em !important;
	}
	.ft_sides {
		flex-direction: column;
		align-items: flex-start !important;
	}
	.ft_top {
		max-width: 27em;
	}
	.ft_floating {
		top: 3.125em !important;
	}
	.dropdown_head,
	.ft_floating {
		border-radius: 5px;
	}
	.dropdown_options {
		-webkit-border-bottom-right-radius: 5px;
		-webkit-border-bottom-left-radius: 5px;
		-moz-border-radius-bottomright: 5px;
		-moz-border-radius-bottomleft: 5px;
		border-bottom-right-radius: 5px;
		border-bottom-left-radius: 5px;
	}
	.dropdown {
		margin-top: 1.25em;
	}
	.ft_side:first-child .ft_cols {
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.ft_side:first-child .ft_col {
		width: 40%;
	}
	.ft_side:first-child .ft_col,
	.ft_middle {
		margin-bottom: 3em
	}
	.ft_social a {
		padding-left: 1.5em;
		padding-right: 1.5em;
	}
	.ft_side {
		width: 100% !important;
	}
	.copyrights .micro {
		flex-direction: column;
		align-items: flex-start;
		margin-top: 3em;
	}
	.ft_bottom .ft_sides {
		flex-direction: column-reverse;
	}
	.ft_social {
		flex-direction: column;
		align-items: flex-start;
	}
	.ft_social .label {
		margin-bottom: 1em
	}
	.featured_cta {
		margin-top: 3em;
	}
	.inner_bar {
		bottom: 30px;
	}
	.bar_wrap {
		width: calc(100% + (30px * 2));
		margin-top: -50px;
		padding-top: 0;
	}
	.bar_set {
		border-radius: 0;
		border-top: none;
		border-right: none;
		border-left: none;
	}
	.stores_section {
		margin-top: 50px;
	}
	.stores_col .store {
		width: calc(100vw - 60px);
		height: 26.8576377em;
	}
	.stores_col .store_cover {
		height: 20.12em;
	}
	.stores_col .store_bar {
		height: calc(100% - 20.12em);
	}
	.abt_imgs_wrap {
		display: none;
	}
	.inner_wrap.transparent {
		min-height: 600px;
		margin-top: 0;
	}
	.opr_row {
		flex-direction: column;
		margin-top: 0 !important;
	}
	.opr_col {
		width: 100%;
		margin-top: 3em;
	}
	.team_img {
		position: relative !important;
		width: 100% !important;
		transform: none !important;
		margin-top: 2em;
	}
	.team_imgs {
		flex-direction: column;
		height: auto;
	}
	.team_img:first-child {
		height: 35em;
	}
	.team_img:nth-child(2) {
		height: 60em;
	}
	.team_img:nth-child(3) {
		height: 36em;
	}
	.careers_media_wrap {
		width: 110%;
	}
	.careers_media {
		width: 9em;
		height: 9em;
	}
	.careers_media:nth-child(3) {
		top: 5em;
	}
	.articles_set {
		width: 100%;
	}
	.articles_set,
	.article_box {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.article_box:not(:last-child) {
		margin-bottom: 30px;
	}
	.article {
		width: 33.565em !important;
	}
	.htb_card {
		transform: none !important;
		margin-right: 0 !important;
		margin-left: 0 !important;
		width: 100%;
	}
	.htb_body {
		flex-direction: column;
	}
	.htb_card:first-child {
		margin-bottom: 30px;
	}
	html.has-scroll-smooth .app_content {
		max-width: unset;
	}
	html:not(.has-scroll-smooth) .app_content {
		flex-direction: column !important;
		padding-top: 0;
		flex: 1;
	}
	html:not(.has-scroll-smooth)  .card_details {
		width: 100%;
		max-width: unset;
		padding: 2em;
	}
	html:not(.has-scroll-smooth)  .card_photo {
		display: none;
	}
	.app_banner_content {
		bottom: 4em;
		z-index: 199;
	}
	.app_banner_content p {
		max-width: 68%;
	}
	.app_banner_content h4 {
		/* display: none; */
		max-width: 60%;
	}
	.isDesktop .app_banner_content .qr_set {
		display: none
	}
	.app_banner_circle {
		width: 38em;
		height: 38em;
	}
	.deals_side {
		width: 100%;
	}
	.deal_body {
		padding: 30px;
	}
	.close_side {
		top: 30px;
	}
	#fixed_share {
		display: flex;
		flex-direction: column-reverse;
	}
	.article_head,
	.article_head h6,
	.article_body p,
	.article_body h5,
	.article_head {
		max-width: 100%;
	}
	.article_body p:not(.sec_column_para) {
		max-width: 100%;
	}
	.share_set {
		width: 100%;
		top: 0;
		position: relative;
		transform: none !important;
		margin-top: 3em;
		align-items: center;
	}
	.share_icons {
		flex-direction: row;
	}
	.share_set p {
		font-size: 1em;
	}
	.share_icons a {
		margin-top: 0.9375em;
	}
	.opr_head p {
		max-width: 100%;
	}
	.int_cols {
		margin-top: 2em;
		width: calc(100% + 30px);
	}
	.int_box {
		width: 100%;
		height: 10em;
	}
	.int_col {
		margin-bottom: 0;
		margin-top: 0;
		width: 33.33%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 15px;
		text-align: center;
	}
	.int_box p {
		font-size: 1em
	}
	.int_box svg {
		height: 3em;
		margin-bottom: 1em;
	}
	.map_set {
		min-height: unset;
		height: auto;
	}
	.map_set > i {
		display: none;
	}
	.map_content {
		justify-content: flex-start;
		padding: 4em 0;
	}
	.faq_head h6,
	.faq_content {
		max-width: 90%;
	}
	.burger_menu {
		padding-top: 30px;
		padding-bottom: 30px;
		height: calc(var(--vh,1vh) * 100);
	}
	.burger_content {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.burger_content .ft_side {
		width: auto !important;
	}
	.burger_content .ft_cols {
		flex-direction: column;
	}
	.burger_content .ft_col {
		flex: 1;
		width: auto !important;
		margin-bottom: 2em !important;
	}
	.burger_content .ft_col:last-child {
		margin-bottom: 0 !important
	}
	.burger_content .ft_col .label {
		font-size: 2.3em;
		margin-bottom: 0.3em;
	}
	.burger_content .ft_col a {
		font-size: 1.5em;
	}
	.label {
		font-size: 1.5em;
	}
	#casestudy .wrap h3 {
		width: 100%;
		max-width: 100%;
	}
	#casestudy .figure {
		flex: 100% 0 0 !important;
	}
	.quote {
		padding: 5em 4em;
		flex-direction: column;
	}
	.quote_media {
		width: 100%;
		padding-top: 0;
		padding-bottom: 4em;
		margin: 0 !important;
	}
	.figures h3 {
		font-size: 4em;
	}
	.numbers {
		padding-right: 4em;
		padding-left: 4em;
		padding-top: 0;
		padding-bottom: 5em;
	}
	.head h4 {
		max-width: 100%
	}
	#casestudy .wrap:not(.transparent) .head p {
		max-width: 100% !important;
	}
	.case_cta h4 {
		max-width: 100%;
	}
	.sub_logo {
		width: 2.5em;
		top: 6em;
	}
	.f_card {
		width: 100%;
	}
	.f_card:not(:first-child) {
		margin-top: 5em;
	}
	#farahWrap {
		margin-bottom: 50px;
	}
}

@media (max-width: 440px){
	body {
		font-size: 2.6vw;
	}
	.careers_media_wrap {
		height: 55em;
	}
	.careers_media {
		width: 12em;
		height: 12em;
	}
	.careers_media:nth-child(3) {
		top: 2em;
	}
}